.list-item {
  display: block;
  min-height: $panel-list-item-min-height;
  transition: all 0.2s ease-in;
  color: $panel-list-item-color;
  .btn-link {
    font-weight: bold;
    svg {
      vertical-align: sub;
    }
  }
  &.change-request-item{
    min-height: auto;
    padding: 20px 0px;
  }

  &-subtitle {
    font-size: $panel-list-item-sub-font-size;
    line-height: $panel-list-item-sub-line-height;
    color: $panel-list-item-sub-color;
    font-weight: normal;
  }

  .subtitle {
    font-size: 0.8em;
  }

  &-sm {
    min-height: 60px;
  }
  a:hover svg {
    path {
      fill: $primary;
    }
  }
}

.dark {
  .list-item {
    color: $text-icon-light;
    &.clickable:hover .btn-link {
      color: white;
    }
    .btn-link {
      color: white;
    }
  }
}
